import React, { useEffect, useRef, useState } from 'react';
import _ from '@lodash';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Formsy from 'formsy-react';
import TextField from 'app/components/forms/TextField';
import Chips from 'app/components/forms/Chips';
import Checkbox from 'app/components/forms/Checkbox';
import DateTime from 'app/components/forms/DateTime';
import toast from 'app/services/toastService/toast';
import { ROLES } from 'app/consts';
import SubmitFab from '../forms/SubmitFab';
import apiService from '../../services/apiService';
import { lite } from '../../services/apiService/api/lite';

const useStyles = makeStyles(theme => ({
  paper: {
    color: theme.palette.text.primary,
    [theme.breakpoints.up('sm')]: {
      maxWidth: 900
    }
  },
  content: {
    paddingBottom: theme.spacing(12)
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1.5),
    top: theme.spacing(1.5),
    padding: theme.spacing(0.2),
    zIndex: 9999,
    color: theme.palette.grey[500],
    backgroundColor: '#ffffffaa'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const rolesOptions = [
  { value: ROLES.ANESTHESIA, role: ROLES.ANESTHESIA, label: 'Anesthesiologist' },
  {
    value: ROLES.ANESTHESIA_RESIDENT,
    role: ROLES.ANESTHESIA_RESIDENT,
    label: 'Anesthesia Resident'
  },
  { value: ROLES.CRNA, role: ROLES.CRNA, label: 'CRNA' },
  { value: ROLES.APP, role: ROLES.APP, label: 'APP' },
  { value: ROLES.SURGICAL_FELLOW, role: ROLES.SURGICAL_FELLOW, label: 'Surgical fellow' },
  { value: ROLES.RESIDENT, role: ROLES.RESIDENT, label: 'Surgical Resident' },
  { value: ROLES.NEUROPHYSIOLOGIST, role: ROLES.NEUROPHYSIOLOGIST, label: 'Neurophysiologist' },
  { value: ROLES.NURSE, role: ROLES.NURSE, label: 'Nurse' },
  { value: ROLES.NURSE_MANAGER, role: ROLES.NURSE_MANAGER, label: 'Nurse Manager' },
  { value: ROLES.CST, role: ROLES.CST, label: 'CST' },
  { value: ROLES.ORSTAFF, role: ROLES.ORSTAFF, label: 'Or Staff' },
  { value: ROLES.ATTENDING, role: ROLES.ATTENDING, label: 'Surgeon' },
  { value: ROLES.VENDOR_REP, role: ROLES.VENDOR_REP, label: 'Vendor rep' },
  { value: ROLES.OTHER, role: ROLES.OTHER, label: 'Other' },
  { value: ROLES.INVITER, role: ROLES.INVITER, label: 'Inviter' }
].sort((a, b) => a.label.localeCompare(b.label));

function UsersDialog(props) {
  const { t } = useTranslation();
  const classes = useStyles(props);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [isFormValid, setIsFormValid] = useState(false);
  const adminSites = props.sites;

  const formRef = useRef(null);

  const { user } = props;
  const [values, setValues] = useState(
    user
      ? {
          ...user,
          sites: user.userSites
            ? user.userSites.map(us => ({ label: us.site.name, value: us.siteId }))
            : []
        }
      : user
  );
  const roles = values.roles ? values.roles : [];
  useEffect(() => {
    setValues(
      user
        ? {
            ...user,
            sites: user.userSites
              ? user.userSites.map(us => ({ label: us.site.name, value: us.siteId }))
              : []
          }
        : user
    );
  }, [user]);

  const handleSubmit = async model => {
    setIsFormValid(false);
    try {
      const modelToSubmit =
        adminSites.length === 1
          ? { ...model, sites: adminSites.map(({ id, name }) => ({ label: name, value: id })) }
          : model;

      // validate that there's only one timezone selected
      const tzs = modelToSubmit.sites.map(s => {
        const site = adminSites.find(as => as.id === s.value);
        return site.timezone;
      });
      if (new Set(tzs).size > 1) {
        toast.error(`A user cannot have sites in multiple timezones: ${tzs.join(', ')}`);
        return;
      }

      // create sites if needed
      if (modelToSubmit.sites.length === 0) {
        const siteName = `${modelToSubmit.firstName} ${modelToSubmit.lastName}`;
        const newSite = await apiService.addSite(
          siteName,
          true,
          JSON.stringify(['c_spec_neurosurgery']),
          true,
          'America/New_York'
        );
        modelToSubmit.sites = [{ label: siteName, value: newSite.insertSitesOne.id }];
      }

      const handleFollowAll = modelToSubmit.followAll !== user.followAll;
      const res = await props.submitData(user.id, modelToSubmit, handleFollowAll);
      if (!res) {
        throw new Error('Error while creating user');
      }

      props.closeDialog();
    } catch (err) {
      console.log(err);
      toast.error(err.message);
    }
    setIsFormValid(true);
  };

  const handleClose = () => {
    props.closeDialog();
  };

  const isCreate = user.id === 'new';
  const getSubmitLabel = () => {
    if (isCreate) {
      return values.sites?.length > 0 ? 'CREATE USER' : 'CREATE SERVICE & USER';
    }

    return 'UPDATE USER';
  };
  const showVendorField = roles.some(r => r.role === ROLES.VENDOR_REP);

  // Show only active sites or sites that the user is already a member of. If showing an inactive site, mark it as such.
  const siteOptions = adminSites
    .filter(s => s.isActive || values.sites?.some(vs => vs.value === s.id))
    .map(({ id, name, isActive }) => ({
      label: `${name}${isActive ? '' : ' [INACTIVE]'}`,
      value: id
    }));

  return (
    <Dialog
      classes={{
        paper: clsx(classes.paper, 'w-full')
      }}
      TransitionComponent={Transition}
      onClose={handleClose}
      open={props.user !== false}
      fullScreen={fullScreen}
    >
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={handleClose}
        size="large"
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <h1 style={{ marginBottom: '0' }}>{isCreate ? t('ADD USER') : values.email}</h1>
        {!isCreate && <h5 style={{ marginTop: '0', color: 'gray' }}>{values.id}</h5>}

        <Formsy
          onValidSubmit={handleSubmit}
          onValid={() => setIsFormValid(true)}
          onInvalid={() => setIsFormValid(false)}
          ref={formRef}
          className="flex flex-col"
        >
          {isCreate && (
            <Checkbox
              className="mt-0"
              name="sendInvite"
              value={false}
              label={t('Send invite on create')}
            />
          )}
          <TextField
            label={t('Display Name')}
            placeholder={t('add_user_nickname_hint', '')}
            className="my-16"
            name="nickName"
            value={values.nickName}
            formRef={formRef}
            required
          />

          {isCreate && (
            <TextField
              label={t('Email')}
              placeholder={t('add_user_email_hint', '')}
              className="my-16"
              name="email"
              value={values.email}
              formRef={formRef}
              validations="isEmail"
              required
            />
          )}

          <Chips
            label={t('Roles')}
            className="my-16"
            name="roles"
            value={roles.map(v => ({ ...v, value: v.role }))}
            formRef={formRef}
            options={rolesOptions}
            onChange={(value, clickedOption, form) => {
              setValues({ ...values, roles: value });
            }}
          />

          <TextField
            label={t('Last Name')}
            placeholder={t('add_user_last_name_hint', '')}
            className="my-16"
            name="lastName"
            value={values.lastName}
            formRef={formRef}
          />

          <TextField
            label={t('First Name')}
            placeholder={t('add_user_first_name_hint', '')}
            className="my-16"
            name="firstName"
            value={values.firstName}
            formRef={formRef}
          />

          <TextField
            label={t('Phone Number')}
            placeholder={t('add_user_phone_hint', '')}
            className="my-16"
            name="phone"
            value={values.phone}
            formRef={formRef}
          />

          {showVendorField && (
            <TextField
              label={t('Vendor')}
              placeholder={t('E.g Stryker, Globus')}
              className="my-16"
              name="vendor"
              value={values.vendor}
              formRef={formRef}
            />
          )}

          <Checkbox
            className="mt-10"
            name="isNurseLeader"
            value={_.isUndefined(values.isNurseLeader) ? false : values.isNurseLeader}
            label={t('Contributor')}
          />

          <Checkbox
            className="mt-10"
            name="followAll"
            value={_.isUndefined(values.followAll) ? false : values.followAll}
            label={t('Follow all cases automatically')}
          />

          <DateTime
            className="my-16 mt-22 mb-52"
            label={t('MUTE NOTIFICATIONS UNTIL (INCLUDING)')}
            name="muteUntil"
            format="ll h:mma"
            value={new Date(values.muteUntil) > new Date() ? values.muteUntil : null}
            defaultToTomorrowIfBlank
          />

          {adminSites.length > 1 && (
            <Chips
              label={t('Services')}
              name="sites"
              formRef={formRef}
              value={values.sites}
              options={siteOptions}
              onChange={(value, form) => {
                setValues({ ...values, sites: value });
              }}
              validations={lite ? undefined : 'minLength:1'}
            />
          )}

          <Chips
            label={t('Reminder days')}
            className="my-16"
            name="reminderDays"
            value={values.reminderDays?.map(v => ({ label: v, value: v })) ?? []}
            formRef={formRef}
            options={[
              'Monday',
              'Tuesday',
              'Wednesday',
              'Thursday',
              'Friday',
              'Saturday',
              'Sunday'
            ].map(key => ({
              label: key,
              value: key
            }))}
            onChange={(value, clickedOption, form) => {
              setValues({ ...values, reminderDays: value.map(v => v.value) });
            }}
          />
          <Checkbox
            className="mt-0"
            name="isActive"
            value={_.isUndefined(values.isActive) ? true : values.isActive}
            label={t('Active User')}
          />
          <SubmitFab type="submit" disabled={!isFormValid} label={getSubmitLabel()} />
        </Formsy>
      </DialogContent>
    </Dialog>
  );
}

export default UsersDialog;
