import { gql } from '@apollo/client';
import { DEP_SITE_FULL_DATA, DEP_USER_FULL_DATA, META_FULL_DATA } from './fragments/depAdmin';

const getSites = gql`
  query AdminGetSites {
    sites(order_by: { is_active: desc, name: asc }) {
      ...DepSiteFullData
    }
  }
  ${DEP_SITE_FULL_DATA}
`;

const addDepAdmin = gql`
  mutation AdminAddDepAdmin($role: role_enum!, $user_id: uuid!) {
    insert_user_roles_one(
      object: { role: $role, user_id: $user_id }
      on_conflict: { constraint: user_roles_pkey, update_columns: role }
    ) {
      user_id
    }
  }
`;

const removeDepAdmin = gql`
  mutation AdminRemoveDepAdmin($role: role_enum!, $user_id: uuid!) {
    delete_user_roles_by_pk(role: $role, user_id: $user_id) {
      user_id
    }
  }
`;

const addSite = gql`
  mutation AdminAddSite(
    $name: String!
    $is_active: Boolean!
    $specialties: String!
    $attending_only: Boolean!
    $timezone: String!
  ) {
    insert_sites_one(
      object: {
        name: $name
        is_active: $is_active
        specialties: $specialties
        attending_only: $attending_only
        timezone: $timezone
      }
    ) {
      id
    }
  }
`;

const updateSiteData = gql`
  mutation AdminUpdateSiteData(
    $is_active: Boolean!
    $name: String!
    $site_id: uuid!
    $specialties: String!
    $attending_only: Boolean!
    $timezone: String!
  ) {
    update_sites_by_pk(
      pk_columns: { id: $site_id }
      _set: {
        name: $name
        is_active: $is_active
        specialties: $specialties
        attending_only: $attending_only
        timezone: $timezone
      }
    ) {
      id
    }
  }
`;

const getUsers = gql`
  query AdminGetUsers {
    users(order_by: [{ is_active: desc }, { nick_name: asc }]) {
      ...DepUserFullData
    }
  }
  ${DEP_USER_FULL_DATA}
`;

const getMetas = gql`
  query AdminGetMetas {
    meta {
      ...MetaFullData
    }
  }
  ${META_FULL_DATA}
`;

const updateMeta = gql`
  mutation AdminUpdateMeta($key: String!, $value: String!) {
    update_meta_by_pk(pk_columns: { key: $key }, _set: { value: $value }) {
      value
    }
  }
`;

const getImports = gql`
  query AdminGetImports {
    imports(order_by: { created_at: desc }) {
      id
      created_at
      key
      status
    }
  }
`;

const updateUserDataRolesAndSites = gql`
  mutation AdminUpdateUserDataRolesAndSites(
    $user_id: uuid!
    $user_roles: [user_roles_insert_input!]!
    $user_data: users_set_input!
    $user_sites: [user_sites_insert_input!]!
    $role_names: [role_enum!]!
    $site_ids: [uuid!]!
  ) {
    update_users_by_pk(pk_columns: { id: $user_id }, _set: $user_data) {
      id
    }
    # Insert user roles, ignore if exists
    insert_user_roles(
      objects: $user_roles
      on_conflict: {
        constraint: user_roles_pkey # Ensure this is the correct constraint name
        update_columns: []
      }
    ) {
      affected_rows
    }
    # Insert user sites, ignore if exists
    insert_user_sites(
      objects: $user_sites
      on_conflict: {
        constraint: user_sites_pkey # Ensure this is the correct constraint name
        update_columns: []
      }
    ) {
      affected_rows
    }
    # Delete roles not in the current list
    delete_user_roles(
      where: {
        user_id: { _eq: $user_id }
        role: { _nin: $role_names } # Using enum type for filtering
      }
    ) {
      affected_rows
    }
    # Delete sites not in the current list
    delete_user_sites(where: { user_id: { _eq: $user_id }, site_id: { _nin: $site_ids } }) {
      affected_rows
    }
  }
`;

const addUserWebHook = gql`
  mutation AdminAddUserToAuth0(
    $email: String!
    $nick_name: String!
    $create_auth_0_user: Boolean!
    $send_invite: Boolean!
    $restore_by_chiefy: Boolean!
    $is_saml: Boolean!
  ) {
    createUser(
      email: $email
      nickname: $nick_name
      create_auth_0_user: $create_auth_0_user
      resend_invite: $send_invite
      restore_by_chiefy: $restore_by_chiefy
      is_saml: $is_saml
    ) {
      success
    }
  }
`;

const notify = gql`
  mutation AdminNotify($user_id: uuid!, $msg: String!) {
    notify(id: $user_id, msg: $msg) {
      success
    }
  }
`;

const followAllActiveCases = gql`
  mutation AdminFollowAllActiveCases($user_id: uuid!) {
    insert_case_followers(
      where: { user_id: { _eq: $user_id }, case: { state: { _neq: "archived" } } }
    ) {
      affected_rows
    }
  }
`;

const unfollowAllActiveCases = gql`
  mutation AdminUnfollowAllActiveCases($user_id: uuid!) {
    delete_case_followers(
      where: { user_id: { _eq: $user_id }, case: { state: { _neq: "archived" } } }
    ) {
      affected_rows
    }
  }
`;

const addUser = gql`
  mutation AdminAddUser($user_data: users_insert_input!) {
    insert_users_one(object: $user_data) {
      id
    }
  }
`;

const queries = {
  getMetas,
  getUsers,
  getSites,
  getImports
};

const mutations = {
  updateMeta,
  addDepAdmin,
  removeDepAdmin,
  updateUserDataRolesAndSites,
  updateSiteData,
  addSite,
  addUser,
  addUserWebHook,
  notify,
  unfollowAllActiveCases
};

const api = {
  queries,
  mutations
};

export default api;
