import { gql } from '@apollo/client';
import { CASE_STATE } from 'app/consts';
import { lite } from './lite';

const TOP_LIMIT = 5;

const getStatsResetAt = gql`
  query CareTeamGetStatsResetAt($id: uuid!) {
    users_by_pk(id: $id) {
      reset_stats_at
    }
  }
`;
const getMyPublishedCasesCount = gql`
    query CareTeamGetMyPublishedCasesCount($user_id: uuid!, $now: timestamptz!) {
        cases_aggregate(
            where: {
                state: { _neq: "${CASE_STATE.NEW}" }
                case_date: { _lte: $now}
                _and: { _or: [{attending_id: { _eq: $user_id }}, { resident_id: { _eq: $user_id } }] }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getMyPublishedCasesCountAsAssistant = gql`
    query MyPublishedCasesCountAsAssistant($user_id: uuid!, $from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(
            where: {
                state: { _neq: "${CASE_STATE.NEW}" }
                resident_id: { _eq: $user_id }
                created_at: { _gt: $from_date }
                case_date: { _lte: $now}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getMyPublishedCasesCountAsAttending = gql`
    query CareTeamGetMyPublishedCasesCountAsAttending($user_id: uuid!, $from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(
            where: {
                state: { _neq: "${CASE_STATE.NEW}" }
                attending_id: { _eq: $user_id }
                created_at: { _gt: $from_date }
                case_date: { _lte: $now}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getMyAddOnPublishedCasesCount = gql`
    query CareTeamGetMyAddOnPublishedCasesCount($user_id: uuid!, $from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(
            where: {
                state: { _neq: "${CASE_STATE.NEW}" }
                is_add_on: { _eq: true}
                resident_id: { _eq: $user_id },
                created_at: { _gt: $from_date }
                case_date: { _lte: $now}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getAllAddOnPublishedCasesCount = gql`
    query DepDirectorGetAllAddOnPublishedCasesCount($now: timestamptz!) {
        cases_aggregate(
            where: {
                state: { _neq: "${CASE_STATE.NEW}" }
                is_add_on: { _eq: true}
                case_date: { _lte: $now}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getCareTeamDashboardAllAddOnPublishedCasesCount = gql`
    query CareTeamDashboardGetAllAddOnPublishedCasesCount($from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(
            where: {
                state: { _neq: "${CASE_STATE.NEW}" }
                is_add_on: { _eq: true}
                created_at: { _gt: $from_date }
                case_date: { _lte: $now}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getMyAddOnBriefDoneCasesCount = gql`
    query CareTeamGetMyAddOnBriefDoneCasesCount($user_id: uuid!, $from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(
            where: {
                is_add_on: { _eq: true}
                case_state_logs: {to_state: {_eq: "${CASE_STATE.BRIEF_IN_REVIEW}"}}
                resident_id: { _eq: $user_id }
                created_at: { _gt: $from_date }
                case_date: { _lte: $now}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getAllAddOnBriefDoneCasesCount = gql`
    query DepDirectorGetAllAddOnBriefDoneCasesCount($now: timestamptz!) {
        cases_aggregate(
            where: {
                is_add_on: { _eq: true}
                case_state_logs: {to_state: {_eq: "${CASE_STATE.BRIEF_IN_REVIEW}"}}
                case_date: { _lte: $now}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getCareTeamDashboardAllAddOnBriefDoneCasesCount = gql`
    query CareTeamDashboardGetAllAddOnBriefDoneCasesCount($from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(
            where: {
                is_add_on: { _eq: true}
                case_state_logs: {to_state: {_eq: "${CASE_STATE.BRIEF_IN_REVIEW}"}}
                created_at: { _gt: $from_date }
                case_date: { _lte: $now}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getMyFeedbackCasesCount = gql`
    query CareTeamGetFeedbackCasesCount($user_id: uuid!) {
        cases_aggregate(
            where: {
                feedback: { _neq: ""}
                case_state_logs: {to_state: {_eq: "${CASE_STATE.COMPLETED}"}}
                attending_id: { _eq: $user_id }
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getAllFeedbackCasesCount = gql`
    query DepDirectorGetAllFeedbackCasesCount {
        cases_aggregate(
            where: {
                feedback: { _neq: ""}
                case_state_logs: {to_state: {_eq: "${CASE_STATE.COMPLETED}"}}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;
const getCareTeamDashboardAllFeedbackCasesCount = gql`
    query CareTeamDashboardGetAllFeedbackCasesCount {
        cases_aggregate(
            where: {
                feedback: { _neq: ""}
                case_state_logs: {to_state: {_eq: "${CASE_STATE.COMPLETED}"}}
            }
        ) {
            aggregate {
                count
            }
        }
    }
`;

const getCareTeamMyCasesDataMerged = gql`
  query CareTeamMyCasesDataMerged($user_id: uuid!, $from_date: timestamptz!) {
    count_cases_by_state {
      count
      to_state
    }
    count_my_cases_by_state_as_attending(args: { uid: $user_id, from_date: $from_date }) {
      count
      to_state
    }
    count_my_cases_by_state_as_assistant(args: { uid: $user_id, from_date: $from_date }) {
      count
      to_state
    }
  }
`;
const getCareTeamAllCasesCountByState = gql`
  query CareTeamGetAllCasesCountByState {
    count_cases_by_state {
      count
      to_state
    }
  }
`;

const getMyCasesCountByStateAsAttending = gql`
  query CareTeamGetMyCasesCountByStateAsAttending($user_id: uuid!, $from_date: timestamptz!) {
    count_my_cases_by_state_as_attending(args: { uid: $user_id, from_date: $from_date }) {
      count
      to_state
    }
  }
`;

const getMyCasesCountByStateAsAssistant = gql`
  query CareTeamGetMyCasesCountByStateAsAssistant($user_id: uuid!, $from_date: timestamptz!) {
    count_my_cases_by_state_as_assistant(args: { uid: $user_id, from_date: $from_date }) {
      count
      to_state
    }
  }
`;
const getAllPublishedCasesCount = gql`
    query DepDirectorGetAllPublishedCasesCount($now: timestamptz!) {
        cases_aggregate(where: {
            state: {
                _neq: "${CASE_STATE.NEW}"
            },
            case_date: {
                _lte: $now
            }
        }) {
            aggregate {
                count
            }
        }
    }
`;

const getCareTeamDashboardAllPublishedCasesCount = gql`
    query CareTeamDashboardGetAllPublishedCasesCount($from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(where: {
            created_at: {
                _gt: $from_date
            },
            case_date: {
                _lte: $now
            },
            state: {
                _neq: "${CASE_STATE.NEW}"
            }
        }) {
            aggregate {
                count
            }
        }
    }
`;

const getMyBriefDoneCasesCount = gql`
    query CareTeamGetMyBriefDoneCasesCount($user_id: uuid!, $from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(where: {
            created_at: {
                _gt: $from_date
            },
            case_date: {
                _lte: $now
            },
            case_state_logs: {
                from_state: {
                    _eq: "${CASE_STATE.IN_BRIEF}"
                },
                to_state: {
                    _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                },
                user: {
                    roles: {
                        role: {
                            _eq: "resident"
                        }
                    }
                }
            },
            resident_id: {
                _eq: $user_id
            }
        }) {
            aggregate {
                count
            }
        }
    }
`;

const getMyBriefsToReviewAsAttending = gql`
    query CareTeamGetMyBriefsToReviewAsAttending($user_id: uuid!, $from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(where: {
            _and: [{
                created_at: {
                    _gt: $from_date
                }
            }, {
                attending_id: {
                    _eq: $user_id
                }
            }, {
                _or: [{
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                        },
                        to_state: {
                            _neq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                        }
                    }
                }, {
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.IN_BRIEF}"
                        },
                        to_state: {
                            _eq: "${CASE_STATE.READY}"
                        }
                    }
                }, {
                    state: {
                        _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                    },
                    case_date: {
                        _lte: $now
                    }
                }, {
                    resident_id: {
                        _is_null: true
                    },
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.IN_BRIEF}"
                        },
                        to_state: {
                            _neq: "${CASE_STATE.IN_BRIEF}"
                        }
                    }
                }
                ]
            }
            ]
        }) {
            aggregate {
                count
            }
        }
    }
`;

const getMyBriefsToReviewAsAttendingCases = gql`
    query CareTeamGetMyBriefsToReviewAsAttendingCases($user_id: uuid!, $from_date: timestamptz!, $now: timestamptz!) {
        cases(where: {
            _and: [{
                created_at: {
                    _gt: $from_date
                }
            }, {
                attending_id: {
                    _eq: $user_id
                }
            }, {
                _or: [{
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                        },
                        to_state: {
                            _neq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                        }
                    }
                }, {
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.IN_BRIEF}"
                        },
                        to_state: {
                            _eq: "${CASE_STATE.READY}"
                        }
                    }
                }, {
                    state: {
                        _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                    },
                    case_date: {
                        _lte: $now
                    }
                }, {
                    resident_id: {
                        _is_null: true
                    },
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.IN_BRIEF}"
                        },
                        to_state: {
                            _neq: "${CASE_STATE.IN_BRIEF}"
                        }
                    }
                }
                ]
            }
            ]
        }, order_by: {
            case_date: asc
        }) {
            case_date
        }
    }
`;

const getMyBriefsReviewedAsAttendingCases = gql`
    query CareTeamGetMyBriefsReviewedAsAttendingCases($user_id: uuid!, $from_date: timestamptz!) {
        cases(where: {
            created_at: {
                _gt: $from_date
            },
            attending_id: {
                _eq: $user_id
            },
            case_state_logs: {
                to_state: {
                    _eq: "${CASE_STATE.READY}"
                }
            }
        }, order_by: {
            case_date: asc
        }) {
            case_date
        }
    }
`;

const getAllBriefsToReviewAsAttending = gql`
    query DepDirectorGetAllBriefsToReviewAsAttending($from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(where: {
            _and: [{
                created_at: {
                    _gt: $from_date
                }
            }, {
                _or: [{
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                        },
                        to_state: {
                            _neq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                        }
                    }
                }, {
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.IN_BRIEF}"
                        },
                        to_state: {
                            _eq: "${CASE_STATE.READY}"
                        }
                    }
                }, {
                    state: {
                        _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                    },
                    case_date: {
                        _lte: $now
                    }
                }, {
                    resident_id: {
                        _is_null: true
                    },
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.IN_BRIEF}"
                        },
                        to_state: {
                            _neq: "${CASE_STATE.IN_BRIEF}"
                        }
                    }
                }
                ]
            }
            ]
        }) {
            aggregate {
                count
            }
        }
    }
`;

const getAllBriefsToReviewAsAttendingCases = gql`
    query GetAllBriefsToReviewAsAttendingCases($from_date: timestamptz!, $now: timestamptz!) {
        cases(where: {
            _and: [{
                created_at: {
                    _gt: $from_date
                }
            }, {
                _or: [{
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                        },
                        to_state: {
                            _neq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                        }
                    }
                }, {
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.IN_BRIEF}"
                        },
                        to_state: {
                            _eq: "${CASE_STATE.READY}"
                        }
                    }
                }, {
                    state: {
                        _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                    },
                    case_date: {
                        _lte: $now
                    }
                }, {
                    resident_id: {
                        _is_null: true
                    },
                    case_state_logs: {
                        from_state: {
                            _eq: "${CASE_STATE.IN_BRIEF}"
                        },
                        to_state: {
                            _neq: "${CASE_STATE.IN_BRIEF}"
                        }
                    }
                }
                ]
            }
            ]
        }, order_by: {
            case_date: asc
        }) {
            id
            case_date
        }
    }
`;

const getAllBriefsReviewedAsAttendingCases = gql`
    query GetAllBriefsReviewedAsAttendingCases($from_date: timestamptz!) {
        cases(where: {
            created_at: {
                _gt: $from_date
            },
            case_state_logs: {
                to_state: {
                    _eq: "${CASE_STATE.READY}"
                }
            }
        }, order_by: {
            case_date: asc
        }) {
            id
            case_date
        }
    }
`;

const getWeeklyReportBriefsToReviewCasesNonLite = gql`
    query GetWeeklyReportBriefsToReviewCases($from_date: timestamptz!, $to_date: timestamptz!, $site_id: uuid!, $attending_ids: [uuid!]) {
        cases(where: {
            case_date: {
                _gte: $from_date
                _lt: $to_date
            },
            site_id: {
                _eq: $site_id
            },
            state: {
                _neq: "${CASE_STATE.NEW}"
            },
            attending_id: {
                _in: $attending_ids
            }
        }, order_by: {
            case_date: asc
        }) {
            id
            case_date
            attending_id
        }
    }
`;
const getWeeklyReportBriefsToReviewCasesLite = gql`
    query GetWeeklyReportBriefsToReviewCases($from_date: timestamptz!, $to_date: timestamptz!, $site_id: uuid!, $attending_ids: [uuid!]) {
        cases(where: {
            created_at: {
                _gte: $from_date
                _lt: $to_date
            },
            site_id: {
                _eq: $site_id
            },
            state: {
                _neq: "${CASE_STATE.NEW}"
            },
            attending_id: {
                _in: $attending_ids
            }
        }, order_by: {
            case_date: asc
        }) {
            id
            created_at
            attending_id
        }
    }
`;

const getWeeklyReportBriefsReviewedCasesNonLite = gql`
    query GetWeeklyReportBriefsReviewedCases($from_date: timestamptz!, $to_date: timestamptz!, $site_id: uuid!, $attending_ids: [uuid!]) {
        cases(where: {
            case_date: {
                _gte: $from_date
                _lt: $to_date
            },
            site_id: {
                _eq: $site_id
            },
            state: {
                _neq: "${CASE_STATE.NEW}"
            },
            case_state_logs: {
                to_state: {
                    _eq: "${CASE_STATE.READY}"
                }
            },
            attending_id: {
                _in: $attending_ids
            }
        }, order_by: {
            case_date: asc
        }) {
            id
            case_date
            attending_id
        }
    }
`;
const getWeeklyReportBriefsReviewedCasesLite = gql`
    query GetWeeklyReportBriefsReviewedCases($from_date: timestamptz!, $to_date: timestamptz!, $site_id: uuid!, $attending_ids: [uuid!]) {
        cases(where: {
            created_at: {
                _gte: $from_date
                _lt: $to_date
            },
            site_id: {
                _eq: $site_id
            },
            state: {
                _neq: "${CASE_STATE.NEW}"
            },
            case_state_logs: {
                to_state: {
                    _eq: "${CASE_STATE.READY}"
                }
            }
            attending_id: {
                _in: $attending_ids
            }
        }, order_by: {
            case_date: asc
        }) {
            id
            created_at
            attending_id
        }
    }
`;

const getNonParticipatingAttendings = gql`
  query GetNonParticipatingAttendings($site_id: uuid!) {
    users(
      where: {
        user_sites: { site_id: { _eq: $site_id } }
        roles: { role: { _eq: "attending" } }
        attending_cases_aggregate: {
          count: {
            predicate: { _eq: 0 }
            filter: { case_state_logs: { to_state: { _eq: "ready" } } }
          }
        }
      }
    ) {
      id
      nick_name
    }
  }
`;

// the next two functions are the same: one for care team and one for director
const careTeamGetAllBriefDoneCasesCount = gql`
    query CareTeamAllBriefDoneCasesCount($from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(where: {
            created_at: {
                _gt: $from_date
            },
            case_date: {
                _lte: $now
            },
            case_state_logs: {
                from_state: {
                    _eq: "${CASE_STATE.IN_BRIEF}"
                },
                to_state: {
                    _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                },
                user: {
                    roles: {
                        role: {
                            _eq: "resident"
                        }
                    }
                }
            }
        }) {
            aggregate {
                count
            }
        }
    }
`;

const getAllBriefDoneCasesCount = gql`
    query DepDirectorAllBriefDoneCasesCount($from_date: timestamptz!, $now: timestamptz!) {
        cases_aggregate(where: {
            created_at: {
                _gt: $from_date
            },
            case_date: {
                _lte: $now
            },
            case_state_logs: {
                from_state: {
                    _eq: "${CASE_STATE.IN_BRIEF}"
                },
                to_state: {
                    _eq: "${CASE_STATE.BRIEF_IN_REVIEW}"
                },
                user: {
                    roles: {
                        role: {
                            _eq: "resident"
                        }
                    }
                }
            }
        }) {
            aggregate {
                count
            }
        }
    }
`;

const getAllCasesCountByState = gql`
  query DepDirectorGetAllCasesCountByState {
    count_cases_by_state {
      count
      to_state
    }
  }
`;

const getAllCasesCountBySite = gql`
  query DepDirectorGetAllCasesCountBySite {
    count_cases_by_site {
      count
      name
    }
  }
`;

const getAllCurrentYearCasesCountByMonth = gql`
  query DepDirectorGetAllCurrentYearCasesCountByMonth {
    current_year_cases_by_month(order_by: { case_month: asc }) {
      count
      case_month
    }
  }
`;

const getMyCurrentYearCasesCountByMonthAsAssistant = gql`
  query CareTeamGetMyCurrentYearCasesCountByMonthAsAssistant($user_id: uuid!) {
    my_current_year_cases_by_month_as_assistant(
      args: { uid: $user_id }
      order_by: { case_month: asc }
    ) {
      count
      case_month
    }
  }
`;

const getMyCurrentYearCasesCountByMonthAsAttending = gql`
  query CareTeamGetMyCurrentYearCasesCountByMonthAsAttending($user_id: uuid!) {
    my_current_year_cases_by_month_as_attending(
      args: { uid: $user_id }
      order_by: { case_month: asc }
    ) {
      count
      case_month
    }
  }
`;

const getAllCasesCountByType = gql`
  query DepDirectorGetAllCasesCountByType {
    cases_by_type {
      count
      name
    }
  }
`;

const getMyCasesCountByTypeAsAssistant = gql`
  query CareTeamGetMyCasesCountByTypeAsAssistant($user_id: uuid!) {
    count_my_cases_by_type_as_assistant(args: { uid: $user_id }) {
      count
      name
    }
  }
`;
const getMyCasesCountByTypeAsAttending = gql`
  query CareTeamGetMyCasesCountByTypeAsAttending($user_id: uuid!) {
    count_my_cases_by_type_as_attending(args: { uid: $user_id }) {
      count
      name
    }
  }
`;

const getAllCasesCountByAssistant = gql`
  query DepDirectorGetAllCasesCountByAssistant {
    cases_by_assistant(order_by: { count: desc }) {
      count
      nick_name
    }
  }
`;

const getMyCasesCountByAssistant = gql`
  query CareTeamGetMyCasesCountByAssistant($user_id: uuid!) {
    count_my_cases_by_assistant(args: { uid: $user_id }, order_by: { count: desc }) {
      count
      nick_name
    }
  }
`;

const getAllCasesCountByAttending = gql`
  query DepDirectorGetAllCasesCountByAttending {
    cases_by_attending(order_by: { count: desc }) {
      count
      nick_name
    }
  }
`;

const getMyCasesCountByAttending = gql`
  query CareTeamGetMyCasesCountByAttending($user_id: uuid!) {
    count_my_cases_by_attending(args: { uid: $user_id }, order_by: { count: desc }) {
      count
      nick_name
    }
  }
`;

const getMyTopFeedback = gql`
    query CareTeamGetMyTopFeedback($user_id: uuid!) {
        cases(limit: ${TOP_LIMIT}, order_by: { case_date: desc }, where: { resident_id: { _eq: $user_id }, feedback: { _neq: "" } }) {
            feedback
            procedure_title
            id
        }
    }
`;

const getMyTopLearned = gql`
    query CareTeamGetMyTopLearned($user_id: uuid!) {
        cases(limit: ${TOP_LIMIT}, order_by: { case_date: desc }, where: { learned: { _neq: "" }
            _and: { _or: [{attending_id: { _eq: $user_id }}, { resident_id: { _eq: $user_id } }] }
        }) {
            learned
            procedure_title
            id
        }
    }
`;

const setResetStatsAt = gql`
  mutation CareTeamSetResetStatsAt($user_id: uuid!, $reset_stats_at: timestamptz!) {
    update_users_by_pk(pk_columns: { id: $user_id }, _set: { reset_stats_at: $reset_stats_at }) {
      reset_stats_at
    }
  }
`;

const getMyPublishedCases = gql`
    query CareTeamGetMyPublishedCases($user_id: uuid!, $from_date: timestamptz!, $now: timestamptz!) {
        cases(
            where: {
                state: { _neq: "${CASE_STATE.NEW}" }
                created_at: { _gt: $from_date }
                case_date: { _lte: $now }
                _and: { _or: [{attending_id: { _eq: $user_id }}, { resident_id: { _eq: $user_id } }] }
            }
        ) {
            created_at
            attending_id
            resident_id
            is_add_on
            case_state_logs {
                to_state
            }
        }
    }
`;

const getReportPerUserInDateRangeNonLite = gql`
  query DepDirectorGetReportPerUserInDateRange(
    $from_ts: timestamptz!
    $until_ts: timestamptz!
    $site_id: uuid
  ) {
    report_per_user_in_date_range_and_site(
      args: { from_ts: $from_ts, until_ts: $until_ts, site_ids: $site_id }
    ) {
      site_id
      site {
        name
      }
      user_id
      user {
        nick_name
      }
      att_all_count
      att_briefed_count
      att_total_followers
      att_total_viewers
    }
  }
`;
const getReportPerUserInDateRangeLite = gql`
  query DepDirectorGetReportPerUserInDateRange(
    $from_ts: timestamptz!
    $until_ts: timestamptz!
    $site_id: uuid
  ) {
    report_per_user_in_date_range_and_site: report_per_user_in_date_range_and_site_lite(
      args: { from_ts: $from_ts, until_ts: $until_ts, site_ids: $site_id }
    ) {
      site_id
      site {
        name
      }
      user_id
      user {
        nick_name
      }
      att_all_count
      att_briefed_count
      att_total_followers
      att_total_viewers
    }
  }
`;

const getCaseFollowersInDateRangeNonLite = gql`
  query DepDirectorGetCaseFollowersInDateRange(
    $from_ts: timestamptz!
    $until_ts: timestamptz!
    $site_id: uuid!
    $attending_ids: [uuid!]
  ) {
    case_followers(
      where: {
        case: {
          case_date: { _gte: $from_ts, _lte: $until_ts }
          site_id: { _eq: $site_id }
          attending_id: { _in: $attending_ids }
        }
      }
    ) {
      case {
        site {
          name
        }
      }
      user {
        id
        nick_name
        first_name
        last_name
        roles {
          role
        }
      }
      last_seen
    }
  }
`;

const getCaseFollowersInDateRangeLite = gql`
  query DepDirectorGetCaseFollowersInDateRange(
    $from_ts: timestamptz!
    $until_ts: timestamptz!
    $site_id: uuid!
    $attending_ids: [uuid!]
  ) {
    case_followers(
      where: {
        case: {
          created_at: { _gte: $from_ts, _lte: $until_ts }
          site_id: { _eq: $site_id }
          attending_id: { _in: $attending_ids }
        }
      }
    ) {
      case {
        site {
          name
        }
      }
      user {
        id
        nick_name
        first_name
        last_name
        roles {
          role
        }
      }
      last_seen
    }
  }
`;

const getCaseFeedbackInDateRangeNonLite = gql`
  query DepDirectorGetCaseFeedbackInDateRange(
    $from_ts: timestamptz!
    $until_ts: timestamptz!
    $site_id: uuid!
    $attending_ids: [uuid!]
  ) {
    plan_feedbacks(
      where: {
        case: {
          case_date: { _gte: $from_ts, _lte: $until_ts }
          site_id: { _eq: $site_id }
          attending_id: { _in: $attending_ids }
        }
      }
      order_by: { case_id: asc }
    ) {
      case {
        procedure_title
        case_state_logs {
          from_state
          to_state
        }
      }
      addi_info_selection
      text
      what_went_well_selection
      what_went_well_text
      rating
      how_to_improve_selection
      how_to_improve_text
      user {
        nick_name
        roles {
          role
        }
      }
      case_id
      created_by_id
    }
  }
`;

const getCaseFeedbackInDateRangeLite = gql`
  query DepDirectorGetCaseFeedbackInDateRange(
    $from_ts: timestamptz!
    $until_ts: timestamptz!
    $site_id: uuid!
    $attending_ids: [uuid!]
  ) {
    plan_feedbacks(
      where: {
        case: {
          created_at: { _gte: $from_ts, _lte: $until_ts }
          site_id: { _eq: $site_id }
          attending_id: { _in: $attending_ids }
        }
      }
      order_by: { case_id: asc }
    ) {
      case {
        procedure_title
        case_state_logs {
          from_state
          to_state
        }
      }
      addi_info_selection
      text
      what_went_well_selection
      what_went_well_text
      rating
      how_to_improve_selection
      how_to_improve_text
      user {
        nick_name
        roles {
          role
        }
      }
      case_id
      created_by_id
    }
  }
`;

const getChatCountInDataRangeNonLite = gql`
  query DepDirectorGetChatCountInDataRange(
    $from_ts: timestamptz!
    $until_ts: timestamptz!
    $site_id: uuid!
    $attending_ids: [uuid!]
  ) {
    cases_aggregate(
      where: {
        site: { id: { _eq: $site_id } }
        case_date: { _gte: $from_ts, _lte: $until_ts }
        comments: { id: { _is_null: false } }
        attending_id: { _in: $attending_ids }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;
const getChatCountInDataRangeLite = gql`
  query DepDirectorGetChatCountInDataRange(
    $from_ts: timestamptz!
    $until_ts: timestamptz!
    $site_id: uuid!
    $attending_ids: [uuid!]
  ) {
    cases_aggregate(
      where: {
        site: { id: { _eq: $site_id } }
        created_at: { _gte: $from_ts, _lte: $until_ts }
        comments: { id: { _is_null: false } }
        attending_id: { _in: $attending_ids }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

const getReadyCaseStateLogsTimeInDataRangeNonLite = gql`
  query DepDirectorGetReadyCaseStateLogsTimeInDataRange(
    $from_ts: timestamptz!
    $until_ts: timestamptz!
    $site_id: uuid!
    $attending_ids: [uuid!]
  ) {
    case_state_logs(
      where: {
        from_state: { _neq: "ready" }
        to_state: { _eq: "ready" }
        case: {
          site: { id: { _eq: $site_id } }
          case_date: { _gte: $from_ts, _lte: $until_ts }
          attending_id: { _in: $attending_ids }
        }
      }
      order_by: { case: { case_date: asc } }
    ) {
      case {
        case_date
        attending {
          nick_name
        }
      }
      created_at
    }
  }
`;

const getReadyCaseStateLogsTimeInDataRangeLite = gql`
  query DepDirectorGetReadyCaseStateLogsTimeInDataRange(
    $from_ts: timestamptz!
    $until_ts: timestamptz!
    $site_id: uuid!
    $attending_ids: [uuid!]
  ) {
    case_state_logs(
      where: {
        from_state: { _neq: "ready" }
        to_state: { _eq: "ready" }
        case: {
          site: { id: { _eq: $site_id } }
          created_at: { _gte: $from_ts, _lte: $until_ts }
          attending_id: { _in: $attending_ids }
        }
      }
      order_by: { case: { case_date: asc } }
    ) {
      case {
        created_at
        attending {
          nick_name
        }
      }
      created_at
    }
  }
`;

const queries = {
  getMyBriefDoneCasesCount,
  getMyBriefsToReviewAsAttending,
  getMyBriefsToReviewAsAttendingCases,
  getMyBriefsReviewedAsAttendingCases,
  getAllBriefsToReviewAsAttending,
  getAllBriefsToReviewAsAttendingCases,
  getAllBriefsReviewedAsAttendingCases,
  getNonParticipatingAttendings,
  careTeamGetAllBriefDoneCasesCount,
  getAllBriefDoneCasesCount,
  getAllCasesCountByState,
  getAllPublishedCasesCount,
  getMyPublishedCasesCount,
  getAllAddOnPublishedCasesCount,
  getMyAddOnPublishedCasesCount,
  getAllAddOnBriefDoneCasesCount,
  getMyAddOnBriefDoneCasesCount,
  getMyFeedbackCasesCount,
  getMyTopFeedback,
  getMyTopLearned,
  getAllCurrentYearCasesCountByMonth,
  getMyCurrentYearCasesCountByMonthAsAssistant,
  getMyCurrentYearCasesCountByMonthAsAttending,
  getAllCasesCountByAttending,
  getMyCasesCountByAttending,
  getAllCasesCountByAssistant,
  getMyCasesCountByAssistant,
  getAllCasesCountByType,
  getMyCasesCountByTypeAsAssistant,
  getMyCasesCountByTypeAsAttending,
  getAllCasesCountBySite,
  getAllFeedbackCasesCount,
  getMyPublishedCasesCountAsAssistant,
  getMyPublishedCasesCountAsAttending,
  getMyCasesCountByStateAsAttending,
  getMyCasesCountByStateAsAssistant,
  getCareTeamDashboardAllFeedbackCasesCount,
  getCareTeamAllCasesCountByState,
  getCareTeamDashboardAllPublishedCasesCount,
  getCareTeamDashboardAllAddOnPublishedCasesCount,
  getCareTeamDashboardAllAddOnBriefDoneCasesCount,
  getStatsResetAt,
  getMyPublishedCases,

  getCareTeamMyCasesDataMerged,
  getReportPerUserInDateRange: lite
    ? getReportPerUserInDateRangeLite
    : getReportPerUserInDateRangeNonLite,

  getCaseFollowersInDateRange: lite
    ? getCaseFollowersInDateRangeLite
    : getCaseFollowersInDateRangeNonLite,
  getCaseFeedbackInDateRange: lite
    ? getCaseFeedbackInDateRangeLite
    : getCaseFeedbackInDateRangeNonLite,
  getChatCountInDataRange: lite ? getChatCountInDataRangeLite : getChatCountInDataRangeNonLite,
  getReadyCaseStateLogsTimeInDataRange: lite
    ? getReadyCaseStateLogsTimeInDataRangeLite
    : getReadyCaseStateLogsTimeInDataRangeNonLite,
  getWeeklyReportBriefsToReviewCases: lite
    ? getWeeklyReportBriefsToReviewCasesLite
    : getWeeklyReportBriefsToReviewCasesNonLite,
  getWeeklyReportBriefsReviewedCases: lite
    ? getWeeklyReportBriefsReviewedCasesLite
    : getWeeklyReportBriefsReviewedCasesNonLite
};

const mutations = {
  setResetStatsAt
};

const api = {
  queries,
  mutations
};

export default api;
