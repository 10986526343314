import Auth0Lock from 'auth0-lock';

import jwtDecode from 'jwt-decode';
import history from '@history';
import * as Sentry from '@sentry/react';
import AUTH_CONFIG from './auth0ServiceConfig';
import urlParams from '../urlParams';

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const INVALID_USER_PASSWORD_ERROR = 'invalid_user_password';
export const NOT_FOUND_IN_HASURA_ERROR = 'acud';

const { REACT_APP_DOMAIN } = process.env;
const appDomain = REACT_APP_DOMAIN ?? 'chiefyteam.com';

class Auth0Service {
  sdk = { auth0Manage: null };

  audPrefix = AUTH_CONFIG.audPrefix;

  lastActivity = false;

  logoutTS = 0;

  init(setWrongPasswordError, success) {
    if (Object.entries(AUTH_CONFIG).length === 0 && AUTH_CONFIG.constructor === Object) {
      if (process.env.NODE_ENV === 'development') {
        console.warn(
          'Missing Auth0 configuration at src/app/services/auth0Service/auth0ServiceConfig.js'
        );
      }
      success(false);
      return;
    }
    const first = window.location.host.split('.')[0];
    const aud = window.location.host.endsWith(':3000')
      ? process.env.REACT_APP_LOCALHOST_AUD
      : first === 'stage-admin'
      ? 'stage'
      : first.endsWith('chf-tay-dev')
      ? 'chf-tay-dev'
      : first === 'zipora-admin'
      ? 'zipora'
      : first;
    const email = urlParams.get('email');
    const options = {
      autoclose: true,
      socialButtonStyle: 'big',
      container: 'login-form-container',
      configurationBaseUrl: `https://${process.env.REACT_APP_AUTH0_DOMAIN}`,
      rememberLastLogin: false,
      allowShowPassword: true,
      allowAutocomplete: true,
      allowPasswordAutocomplete: true,
      auth: {
        // redirect: false,
        redirectUrl: AUTH_CONFIG.callbackUrl,
        responseType: 'token',
        audience: `https://${aud}.${appDomain}/admin-api`,
        params: {
          scope: 'email'
        },
        useRefreshTokens: true
      },
      theme: {
        authButtons: {
          nyumcsaml: {
            displayName: 'LOG IN'
          }
        }
      },
      languageDictionary: {
        forgotPasswordInstructions: `${
          history.location.search === '?resetPassword=true'
            ? "It's time to change your password! We need to do this every 90 days to keep Chiefy secure. Enter your email to receive a password reset email."
            : 'Please enter your email address. We will send you an email to reset your password.'
        }`
      }
    };

    if (email) {
      options.prefill = {
        email
      };
    }
    this.lock = new Auth0Lock(AUTH_CONFIG.clientId, AUTH_CONFIG.domain, options);
    this.handleAuthentication(setWrongPasswordError);
    this.setLogoutTimeout();
    success();
  }

  login = () => {
    if (!this.lock) {
      console.warn("Auth0 Service didn't initialize, check your configuration");
      return false;
    }
    // Call the show method to display the widget.
    if (history.location.search === '?resetPassword=true') {
      return this.lock.show({ initialScreen: 'forgotPassword' });
    }
    return this.lock.show();
  };

  logoutTimeout = false;

  getNewToken = () => {
    this.lock.checkSession({}, (err, authResult) => {
      console.log('checkSession', err, authResult);
      if (err) {
        console.log('err', err);
        return;
      }
      if (!authResult) {
        console.log('!authResult');
        return;
      }
      this.setSession(authResult);
    });
  };

  register = () => {
    if (!this.lock) {
      console.warn("Auth0 Service didn't initialize, check your configuration");

      return false;
    }

    return this.lock.show({
      initialScreen: 'signUp'
    });
  };

  handleAuthentication = setWrongPasswordError => {
    if (!this.lock) {
      return false;
    }

    // Add a callback for Lock's `authenticated` event
    this.lock.on('authenticated', authResult => {
      this.setSession(authResult);
      setWrongPasswordError(false);
    });
    // Add a callback for Lock's `authorization_error` event
    this.lock.on('authorization_error', err => {
      Sentry.captureException(new Error(err.error), {
        extra: { description: err.errorDescription || '' }
      });
      console.log(err);
      console.warn(`Error: ${err.error}. Check the console for further details.`);
      setWrongPasswordError(false);

      if (err?.errorDescription === 'please change your password') {
        window.location = '/login?resetPassword=true';
      } else if (err.code === INVALID_USER_PASSWORD_ERROR) {
        setWrongPasswordError(true);
      } else if (err.errorDescription === 'inactive') {
        window.location = '/login?inactive=true';
      } else if (err.errorDescription === NOT_FOUND_IN_HASURA_ERROR) {
        window.location = `/${NOT_FOUND_IN_HASURA_ERROR}`;
      } else {
        window.location = '/login?hasError=true';
      }
    });

    return true;
  };

  onAuthenticated = callback => {
    if (!this.lock) {
      return false;
    }
    return this.lock.on('authenticated', callback);
  };

  setSession = authResult => {
    if (authResult && authResult.accessToken) {
      // Set the time that the access token will expire at
      const expireMiliSec = authResult.expiresIn * 1000;
      const expiresAt = JSON.stringify(expireMiliSec + new Date().getTime());
      localStorage.setItem('access_token', authResult.accessToken);
      localStorage.setItem('expires_at', expiresAt);
      this.setLogoutTimeout();
    }
  };

  setLogoutTimeout = () => {
    const savedMaxLifetime = localStorage.getItem('expires_at');
    const diff = savedMaxLifetime - Date.now();
    if (diff > 0) {
      if (this.logoutTimeout) {
        clearTimeout(this.logoutTimeout);
        this.logoutTimeout = false;
      }
      this.logoutTimeout = setTimeout(() => {
        this.logout(true);
        this.logoutTimeout = false;
        // redirectToLogin();
      }, diff);
      this.logoutTS = Date.now() + diff;
    }
  };

  logout = (userClick = false) => {
    // Clear access token and ID token from local storage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('auth0.ssodata');
    if (userClick) {
      this.lock.logout({ federated: true });
    }
  };

  isAuthenticated = () => {
    if (!this.lock) {
      return false;
    }
    // Check whether the current time is past the
    // access token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    const isNotExpired = new Date().getTime() < expiresAt;
    if (isNotExpired) {
      return true;
    }

    this.logout();
    return false;
  };

  getAccessToken = () => {
    return localStorage.getItem('access_token');
  };

  getTokenData = () => {
    const token = this.getAccessToken();
    if (!token) {
      return null;
    }
    const decoded = jwtDecode(token);
    if (!decoded) {
      return null;
    }
    return decoded;
  };
}

const instance = new Auth0Service();

export default instance;
