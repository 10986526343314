import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function DashboardWeeklyLineChart(props) {
  Chart.register(ChartDataLabels);

  const chartData = {
    labels: props.data.map(d => d.date),
    datasets: [
      {
        data: props.data.map(d => d.ratio),
        spanGaps: true,
        fill: false,
        lineTension: 0.0,
        borderColor: '#4285F4',
        pointBorderColor: '#4285F4',
        backgroundColor: '#4285F4'
      }
    ]
  };

  return (
    <Card className="flex flex-col justify-between w-full h-full rounded-8 shadow-1 p-16">
      <Typography className="h3" color="textSecondary">
        {props.title}
      </Typography>

      <div className="w-100-p mt-20">
        <Line
          height={props.height || 250}
          data={chartData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false
              },
              datalabels: {
                align: 'end',
                anchor: 'end',
                display: true,
                formatter: (value, ctx) => {
                  return `${value}${props.percentage ? '%' : ''}`;
                },
                color: 'black',
                font: {
                  size: 10
                }
              }
            },
            tooltips: {
              mode: 'index',
              intersect: false
            },
            hover: {
              mode: 'nearest',
              intersect: true
            },
            scales: {
              y: {
                scaleLabel: {
                  display: Boolean(props.yLabel),
                  labelString: props.yLabel
                },
                ticks: {
                  min: 0,
                  callback(value) {
                    return value % 1 === 0 && (!props.percentage || value <= 100)
                      ? `${value}${props.percentage ? '%' : ''}`
                      : '';
                  },
                  color: 'black'
                },
                beginAtZero: true,
                max: props.percentage ? 110 : undefined
              },
              x: {
                gridLines: {
                  display: false
                },
                ticks: {
                  padding: 5,
                  color: 'black'
                }
              }
            }
          }}
        />
      </div>
    </Card>
  );
}

export default React.memo(DashboardWeeklyLineChart);
